import React from 'react';
import { graphql, Link } from 'gatsby';
import get from 'lodash/get';
import DrinksList from '../components/DrinksList';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Icon, Pagination, Segment, SegmentInline } from 'semantic-ui-react';

const PaginatedList = (props) => {
  const drinks = get(props, 'data.allMongodbCocktailmeisterDrinks.edges');
  return (
    <Layout>
      <SEO />
      <h1>{`All ${props.pageContext.category || 'drink'}s`}</h1>
      <DrinksList drinks={drinks} />
      <Segment textAlign="center" className="pagination-nav">
        <Pagination
          defaultActivePage={props.pageContext.humanPageNumber}
          totalPages={props.pageContext.numberOfPages}
          firstItem={null}
          lastItem={null}
          boundaryRange={0}
          siblingRange={0}
          prevItem={{
            as: SegmentInline,
            content: (
              <Link to={props.pageContext.previousPagePath}>
                <Icon name="angle left" />
              </Link>
            ),
            icon: true,
          }}
          ellipsisItem={null}
          nextItem={{
            as: SegmentInline,
            content: (
              <Link to={props.pageContext.nextPagePath}>
                <Icon name="angle right" />
              </Link>
            ),
            icon: true,
          }}
        />
      </Segment>
    </Layout>
  );
};

export default PaginatedList;
export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!, $category: String) {
    allMongodbCocktailmeisterDrinks(
      skip: $skip
      limit: $limit
      sort: { fields: image }
      filter: { category: { eq: $category } }
    ) {
      edges {
        node {
          id
          name
          slug
          ingredients {
            _0
          }
          category
          alcoholic
          glass
          skill
          image
        }
      }
    }
  }
`;
